import React from "react";
import axios from "axios";

import Header from "./Header";
import Footer from "./Footer";
import Main from "./Main";
import About from "./About";
import Portfolio from "./Portfolio";
import Contact from "./Contact";
import Review from "./Review";
import Skills from "./Skills";
import HireMe from "./HireMe";

import $ from "jquery";

function scrollUp(e) {
  e.preventDefault();
  $("body").trigger("mousewheel", [1, 0, 0]);
}

function renderSession() {
  axios.get("https://meharshjain.onrender.com/session");
  return "";
}

function Home() {
  return (
    <>
      {renderSession()}
      <Header />
      <div
        id="ajax-content-wrap"
        className="no-scroll"
        style={{ marginTop: 0 }}
      >
        <div className="blurred-wrap no-scroll">
          <div
            className="nectar-box-roll"
            data-midnight="light"
            style={{ zIndex: 1000, transform: "translate(0px, 0px)" }}
          >
            <div
              className="fullscreen-header"
              data-animate-in-effect="none"
              id="page-header-bg"
              data-text-effect="rotate_in"
              data-bg-pos="center"
              data-alignment="center"
              data-alignment-v="middle"
              data-parallax={0}
              data-height="NaN"
              style={{
                backgroundColor: "rgb(43, 43, 43)",
                height: "100%",
                overflow: "visible",
                willChange: "auto",
              }}
            >
              <div
                className="page-header-bg-image"
                style={{
                  backgroundImage: "url(https://i.stack.imgur.com/Y2AwY.png)",
                  opacity: "0.5",
                }}
              />
              <div className="container" />
            </div>
            <div
              className="overlaid-content"
              data-text-effect="rotate_in"
              data-alignment="center"
              data-alignment-v="middle"
              style={{ height: "100%", willChange: "auto" }}
            >
              <div className="container">
                <div className="row" style={{ "margin-left": 0 }}>
                  <div className="col span_6">
                    <div className="inner-wrap">
                      <h1 className="top-heading">
                        <span className="wraped">
                          <span
                            style={{
                              transform: "rotateX(0deg) translate(0px, 0px)",
                              opacity: 1,
                            }}
                          >
                            Not
                          </span>
                        </span>
                        <span className="wraped">
                          <span
                            style={{
                              transform: "rotateX(0deg) translate(0px, 0px)",
                              opacity: 1,
                            }}
                          >
                            Your
                          </span>
                        </span>
                        <br />
                        <span className="wraped">
                          <span
                            style={{
                              transform: "rotateX(0deg) translate(0px, 0px)",
                              opacity: 1,
                            }}
                          >
                            Average
                          </span>
                        </span>
                        <br />
                        <span className="wraped">
                          <span
                            style={{
                              transform: "rotateX(0deg) translate(0px, 0px)",
                              opacity: 1,
                            }}
                          >
                            Software
                          </span>
                        </span>
                        <br />
                        <span className="wraped">
                          <span
                            style={{
                              transform: "rotateX(0deg) translate(0px, 0px)",
                              opacity: 1,
                            }}
                          >
                            Engineer
                          </span>
                        </span>
                      </h1>
                      <span
                        className="subheader"
                        style={{
                          transform: "rotateX(0deg) translate(0px, 0px)",
                          opacity: 1,
                        }}
                      >
                        Get ready to turn your ideas into reality <br />
                        <br />
                        <i>Harsh Jain</i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="scroll-down-wrap">
                <a href="#" className="section-down-arrow">
                  <i className="fa fa-angle-down" />
                </a>
              </div>
            </div>
          </div>
          <div
            className="container-wrap no-scroll nectar-box-roll-class bottomBoxOut no-transform-animation-bottom-out"
            style={{
              paddingBottom: 0,
              opacity: 0,
              height: "100%",
              willChange: "auto",
              paddingRight: 17,
            }}
          >
            <div
              className="meharshjain"
              style={{ fontFamily: "Baloo Paaji !important" }}
            >
              <div className="meharshjain-wrapper">
                <Main />
                <Skills />
                <br />
                <About />
                <HireMe />
                <Portfolio />
                <Review />
                <Contact />
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
      <a id="to-top" onClick={scrollUp}>
        <i className="fa fa-angle-up top-icon" />
      </a>
    </>
  );
}

export default Home;
