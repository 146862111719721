import { React, useState, useEffect } from "react";
import axios from "axios";
import { Carousel } from "react-responsive-carousel";

function Review() {
  const [reviews, setReview] = useState([]);
  useEffect(() => {
    axios
      .get("https://meharshjain.onrender.com/api/review")
      .then((response) => {
        setReview(response.data);
      });
  }, []);
  function renderReviews() {
    var template = [];
    {
      for (let index = 0; index < reviews.length; index++) {
        let image = reviews[index].Img;
        if (!image) image = "assets/imgs/unknown.png";
        template.push(
          <div>
            <div>
              <img src={image} height="200px" style={{ maxWidth: "200px" }} />
              <p className="legend" style={{maxWidth: "25rem", margin: "auto", "margin-left": "-12rem"}}>
                {reviews[index].Name}
                <br />
              </p>
            </div>
            <p style={{maxWidth: "50rem", margin: "0 auto"}}>{reviews[index].Message}</p>
          </div>
        );
      }
    }
    return template;
  }
  return (
    <section className="bg-primary review" style={{ paddingTop: "40px" }}>
    <div
      className="container text-center text-sm-left"
      style={{ width: "40rem" }}
    >
      <h1 className="title text-light">Reviews</h1>
    </div>
    <Carousel
      autoPlay={true}
      interval={10000}
      infiniteLoop={true}
      renderIndicator={false}
      wipeable={true}
      showStatus={false}
      showIndicators={false}
      stopOnHover={true}
    >
      {renderReviews()}
    </Carousel>
    </section>
  );
}
export default Review;
