import { React, useEffect } from "react";
import axios from "axios";
import $ from "jquery";
import { gapi } from "gapi-script";
import GoogleLogin from "react-google-login";

var user = {};
//769480983049-vf9d6odjq593ahusob4198b8ifbfqe14.apps.googleusercontent.com
const clientId =
  "769480983049-79c4kovqigobm7mqoo7dlh88nfvcchqj.apps.googleusercontent.com";

async function submitForm(e) {
  e.preventDefault();
  const formElement = document.querySelector("form.contact-form");
  const formRec = {
    Date: new Date(),
    Ip: "",
    Name: user.fname,
    Email: user.email,
    Img: user.image,
    Message: $("textarea.form-control.message").val(),
  };
  console.log("form submitted " + formRec);
  axios
    .post("https://meharshjain.onrender.com/api/review/submit", formRec)
    .then((response) => {
      console.log("form submitted " + response);
      alert("Review submitted!");
      formElement.reset();
    })
    .catch((error) => {
      // Handle errors
      console.log("form submitted " + error.stack);
      alert("An issue occurred when submitting");
    });
}

function GoogleBtn() {
  const onSuccess = async (googleUser) => {
    var profile = googleUser.getBasicProfile();
    /*     console.log("ID: " + profile.getId());
    console.log("Name: " + profile.getGivenName());
    console.log("Family Name: " + profile.getFamilyName());
    console.log("Image URL: " + profile.getImageUrl()); */
    console.log("Email: " + profile.getEmail());
    const formRec = {
      Date: new Date(),
      profile: profile,
      GID: profile.getId(),
      LName: profile.getFamilyName(),
      Name: profile.getGivenName(),
      Email: profile.getEmail(),
      Img: profile.getImageUrl(),
      Message: "success login google",
    };
    axios
      .post("https://meharshjain.onrender.com/api/success/google", formRec)
      .then((response) => {
        unlockForm(profile);
      })
      .catch((error) => {
        console.log("form submit error" + error.stack);
        alert("An issue occurred when submitting");
      });
  };
  const onFailure = (error) => {
    console.log(error);
  };
  return (
    <div id="my-signin2">
      <GoogleLogin
        clientId={clientId}
        buttonText="Sign in with Google"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
      ></GoogleLogin>
    </div>
  );
}

function unlockForm(profile) {
  user = {
    fname: profile.getGivenName() + " " + profile.getFamilyName(),
    email: profile.getEmail(),
    image: profile.getImageUrl(),
  };
  $("input.form-control.name").val(user.fname);
  $("input.form-control.email").val(user.email);
  $("input.submit-btn").removeAttr("disabled");
  $("textarea.message").removeAttr("disabled");
}

function Contact() {
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "email",
      });
    }

    gapi.load("client:auth2", start);
  }, []);
  return (
    <section className="section" id="contact">
      <div className="container text-center">
        <p className="section-subtitle">
          Impressed?
          <br />
          Sign in and add a review
        </p>
        <h6 className="section-title mb-5">Add Review</h6>
        <GoogleBtn />
        <br />
        <form
          className="contact-form col-md-10 col-lg-12 m-auto"
          onSubmit={submitForm}
        >
          <div className="form-row">
            <div className="form-group col-sm-6">
              <input
                type="text"
                size="50"
                name="Name"
                className="form-control name"
                placeholder="Your name"
                required
                disabled
              />
            </div>
            <div className="form-group col-sm-6">
              <input
                type="email"
                name="Email"
                className="form-control email"
                placeholder="Enter email"
                required
                disabled
              />
            </div>
            <div className="form-group col-sm-12">
              <textarea
                name="Message"
                id="message"
                rows="6"
                className="form-control message"
                placeholder="Add a review"
                disabled
              ></textarea>
            </div>
            <div className="form-group col-sm-12 mt-3">
              <input
                type="submit"
                value="Send Message"
                className="btn btn-outline-primary rounded submit-btn"
                disabled
                style={{ border: "1px solid #8c649b" }}
              />
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}
export default Contact;
