import React from "react";
import ProjectItem from "./ProjectItem";

function Portfolio() {
  return (
    <section className="section" id="portfolio">
      <div className="container text-center">
        <p className="section-subtitle">What I Did ?</p>
        <h6 className="section-title mb-6">Projects</h6>
        <div className="row" style={{margin: 0}}>
          <ProjectItem
            href="/maintenance"
            src="assets/imgs/folio-10.jpg"
            alt="CloudLCMS"
            title="CloudLCMS"
            info="CloudLCMS provide 508-compliant e-learning management system to the Department of Veteran Affairs"
          />
          <ProjectItem
            href="/maintenance"
            src="assets/imgs/folio-9.jpg"
            alt="CloudLCMS AI"
            title="CloudLCMS AI"
            info="website which can generate 508 compliant course response."
          />
          <ProjectItem
            href="/maintenance"
            src="assets/imgs/folio-8.jpg"
            alt="Rooster AI"
            title="Rooster AI"
            info="Website to generate ai generated content and ask real questions. Get complete answers. Chat and create."
          />
          <ProjectItem
            href="/maintenance"
            src="assets/imgs/folio-7.jpg"
            alt="Srr Accessibility Reporting Tool"
            title="Srr Accessibility Reporting Tool"
            info="Application verifies your file against a set of rules that identify possible issues for people who have disabilities"
          />
          <ProjectItem
            href="https://ekartshop-ihs6.onrender.com/Login"
            src="assets/imgs/folio-6.jpg"
            alt="EKartShop"
            title="EKartShop"
            info="React E-Commerce Website to Find out best products within budget"
          />
          <ProjectItem
            href="https://greenchef.onrender.com/"
            src="assets/imgs/folio-5.jpg"
            alt="GreenChef"
            title="GreenChef"
            info="Full Stack Website For A Food E-Commerce Website. Find out best food within budget near you"
          />
          <ProjectItem
            href="/maintenance"
            src="assets/imgs/folio-4.png"
            alt="Homey"
            title="Homey"
            info="Android Application to Find out food products and track orders"
          />
          <ProjectItem
            href="https://missingx.onrender.com/"
            src="assets/imgs/folio-3.jpg"
            alt="MissingX"
            title="MissingX"
            info="FULL STACK WEB SITE TO ADDRESS ISSUE OF LOST ITEMS WITHIN CAMPUS"
          />
          <ProjectItem
            href="https://razzberry.onrender.com/"
            src="assets/imgs/folio-2.jpg"
            alt="Razzberry"
            title="Razzberry"
            info="full stack web site to address the Unemployment and site of job listings"
          />
          <ProjectItem
            href="/maintenance"
            src="assets/imgs/folio-1.jpg"
            alt="BAKE N’ TAKE"
            title="BAKE N’ TAKE"
            info="Full Stack Website For A Cake Bakery website."
          />
        </div>
      </div>
    </section>
  );
}
export default Portfolio;
